import React from 'react';
import useIsSmallScreen from "../hooks/useIsSmallScreen.js";
import Card from '../components/Card.js';
import QuestionClub from '../components/QuestionClub.js';
import Footer from '../components/Footer.js';
import TopBar from '../components/TopBar.js';
import useScrollDistanceFromDiv from '../hooks/useScrollDistanceFromDiv.js';

export default function HomeClub() {
  const isSmallScreen = useIsSmallScreen();
  const { distance: distance1, divRef: divRef1 } = useScrollDistanceFromDiv();
  const { distance: distance2, divRef: divRef2 } = useScrollDistanceFromDiv();
  const { distance: distance3, divRef: divRef3 } = useScrollDistanceFromDiv();
  console.log(distance1, distance2, distance3);

  // Calculer la transformation en fonction de la distance, limitée à 50 pixels pour la première div
  const maxTransform = 50;
  const transformValue = (distance1 >= -570 && distance1 <= 0) ? (distance1 / 570) * maxTransform : (distance1 < -570 ? -maxTransform : 0);

  // Calculer la réduction de la taille proportionnelle au déplacement pour la deuxième div
  const maxScaleIncrease = 0.1; // augmentation maximale de 10%
  const scaleValue = 1 + (distance2 >= -570 && distance2 <= 0 ? (distance2 / 570) * maxScaleIncrease : (distance2 < -570 ? maxScaleIncrease : 0));
  const limitedScaleValue = Math.max(1, Math.min(1.1, scaleValue)); // assure une augmentation maximale de 110%

  // Calculer la transformation de translation pour la troisième div
  const maxSlideDistance = 100; // distance maximale de translation en pixels
  const slideValue = (distance3 >= -300 && distance3 <= 0) ? ((distance3 + 300) / 300) * maxSlideDistance : (distance3 < -300 ? -maxSlideDistance : 0);

  return (
    <div className='flex flex-col bg-black bg-bp-background bg-cover bg-center min-h-screen'>
      <TopBar />
      <div className='w-[20.1rem] h-[6.75rem] mx-auto mt-[9.19rem] px-[0.89rem] md:w-[73rem]'>
        <h1 className='uppercase text-pink-red font-Poppins tracking-[0.2rem] md:text-[2rem]'>B Partner Club</h1>
        <p className='font-Poppins text-white px-2 md:w-[65rem] md:text-[3.75rem] md:mx-auto'>
          Profitez d’avantages exclusifs en utilisant votre compte B Partner
        </p>
        <p className='font-Poppins text-pink-red text-[1.5rem] md:text-[3.5rem]'>
          Prestige ou Premium
        </p>
      </div>

      <div ref={divRef1} className='flex flex-row relative mt-[12.5rem] md:mt-[18rem] md:h-[27rem] md:w-[74rem] md:mx-auto' id='ici'>
        <img
          alt='night club'
          src='img-1b.jpg'
          className='img-black-and-white mx-auto w-[21.25rem] md:w-[42.5rem] md:h-full'
        />
        <img
          src='img-1b-bis.jpg'
          alt='avenue'
          className={`img-black-and-white ${isSmallScreen ? 'absolute -top-28 -left-6 w-[9.375rem] h-[11.5rem]' : 'absolute w-[21.25rem] h-[26.2225rem] left-0 top-20'}`}
          style={{
            transform: `translateX(${-transformValue}px)`,
            transition: 'transform 0.5s linear'
          }}
        />
        <img
          src='image-1c-bis.jpg'
          alt='avenue'
          className={`img-black-and-white ${isSmallScreen ? 'absolute -bottom-32 -right-8 w-[9.375rem] h-[11.5rem]' : 'absolute w-[21.25rem] h-[26.2225rem] right-0 top-20'}`}
          style={{
            transform: `translateX(${transformValue}px)`,
            transition: 'transform 0.5s linear'
          }}
        />
      </div>

      <div className='flex flex-col mt-[12.5rem] w-[21.9rem] mx-auto pt-[2.5rem] md:flex-row md:w-[73rem] md:items-start md:pt-0'>
        <p className='text-white text-[1.25rem] w-[19.0625rem] mx-auto text-left font-Inter font-light md:w-1/2 md:text-[1.75rem] md:mr-2'>
          Conçu pour les plus fidèles, le B Partner Club, transforme chaque interaction avec votre compte B Partner en une 
          opportunité de reconnaissance et de bénéfices uniques.
        </p>
        <p className='text-white mt-[1.13rem] w-[19.0625rem] mx-auto text-left font-light font-Poppins md:w-1/2 md:mt-0  md:ml-2'>
        Chaque transaction et interaction avec votre compte B Partner vous rapproche de récompenses exceptionnelles. Que ce soit lors de l'ouverture de votre compte, l'utilisation de votre carte de débit ou le parrainage de nouveaux clients, chaque geste compte et vous rapporte des points.
        <br /><br/>
        Les points accumulés peuvent être échangés contre des avantages exclusifs : cashback crédité directement sur votre compte, coupons de réduction auprès de nos partenaires de renom, et bien plus encore. Votre fidélité mérite ce qu'il y a de mieux.
        </p>
      </div>

      <div className='flex flex-col w-[20.1rem] h-[5.75rem] mx-auto mt-[8rem] md:w-[73rem] md:h-[12rem]'>
        <h1 className='uppercase text-pink-red font-Poppins tracking-[0.2rem]'>Nos avantages</h1>
        <p className='font-Poppins text-pink-red text-[1.5rem] md:text-[3.7rem]'>
          Des récompenses Elites
        </p>
        <p className='font-Poppins text-white px-2 md:text-[3.75rem]'>
        Chaque transaction est récompensée
        </p>
      </div>

      <div ref={divRef2} id='ici2' className='w-full flex flex-row relative justify-end md:justify-center mt-[2rem] md:w-[73rem] md:mx-auto'>
        <img 
          src='img-2b.png' 
          alt='business man' 
          className='w-[21.25rem] h-[13.68rem] translate-x-4 md:w-[57rem] md:h-[36.73rem] md:mt-8' 
          style={{
            transform: `scale(${limitedScaleValue})`,
            transition: 'transform 0.5s linear'
          }}
        /> 
        <img 
          src='img-2a.png' 
          alt='hand shake' 
          className={`w-[9.68rem] h-[6.68rem] absolute md:w-[22.25rem] md:h-[15.35rem] ${isSmallScreen ? '-left-6 top-12' : '-left-4 top-44'}`}
          style={{
            opacity: 0.7
          }}
        />
      </div>

      <div ref={divRef3} className='flex flex-col mt-[6rem] md:flex-row md:justify-center'>
        <div className='flex flex-col md:flex-row md:items-center md:ml-auto md:-translate-x-8'>
          <div id='ici3' className='linear-background w-[19.0356rem] h-[23.75rem] mx-auto p-[0.5rem] md:translate-x-14 z-10'
            style={{
              transform: !isSmallScreen ? `translateX(${slideValue}px)` : 'none',
              transition: 'transform 0.5s linear'
            }}
          >
            <div className='w-full h-full bg-[#1A1717] text-white text-left flex flex-col justify-center items-center px-8'>
              <div className=''>
                <p className='font-Poppins text-[1.5rem] font-semibold'>Particulier</p>
                <p className=''>Une offre unique, personnalisée 
                et à votre image</p>
              </div>

              <div className='mt-[1.5rem]'>
                <p className='font-Poppins text-[1.5rem] font-semibold'>Professionels</p>
                <p className=''>Des solutions flexibles pour faciliter votre gestion</p>
              </div>
            </div>
          </div>
          <img alt='b partner card' src='img-card.png' className='px-10 mt-8 img-black-and-white md:w-[28.625rem] md:h-auto md:-translate-x-14'/>
        </div>

        <div className="flex flex-col text-white p-6 w-[21.5rem] md:w-[25rem] mx-auto text-left mt-8 md:ml-0">
          <h2 className="text-pink-red font-Poppins tracking-widest font-bold uppercase">Nos Offres</h2>
          <h3 className="text-[2rem] font-Poppins mb-4 tracking-wider mt-2">Souscrivez à une offre adaptée à vos besoins</h3>
          <p className="mb-4 font-DMSans">
          Un compte Premium pour les professionnels qui souhaite une gestion efficace et flexible de leur finance, en ligne et sans tracas.
          </p>
          <p className="mb-4 font-DMSans">
          Un compte Prestige pour les personnes exigeantes, qui souhaitent une personnalisation de la relation avec leur conseiller et un accès à des services VIPs à tout moment.
          </p>
          <button className="mt-4 border-2 border-[#cc133e] text-pink-red py-2 px-4 rounded text-[1.125rem] font-semibold uppercase w-[12.5rem]">
            Coming Soon
          </button>
        </div>
      </div>

      <div className='mt-[3.5rem]'>
        <div className='md:w-[73rem] md:mx-auto'>
        <h1 className='uppercase text-pink-red font-Inter tracking-[0.2rem]'>Le club</h1>
        <p className='text-white font-Poppins text-[1.5rem] w-[19.75rem] mx-auto md:text-[3.5rem] md:w-[47rem]'><span className='text-pink-red'>Une experience</span><br/> évolutive et qui vous accompagne dans la gestion de vos finances</p>
      

        <p className='font-Poppins text-white w-[19.75rem] mx-auto mt-4 md:w-[47rem] md:text-gray-600'>B Partner Club est un programme transparent et évolutif. Il vous permet de progresser d'un niveau de Statut à un autre en accumulant des points.</p>
        </div>
        <div className='flex flex-col mt-8 md:flex-row md:w-[73rem] md:mx-auto'>
          <Card rank='Silver' img='card-1.jpeg' details={'Accès exclusif aux offres Silver'} support={''}/>
          <Card rank='Gold' img='card-2.jpeg' details={'Accès exclusif aux offres Gold'} support={''}/>
          <Card rank='Platinium' img='card-3.jpeg' details={'Accès exclusif aux offres Platine'} support={''}/>
          <Card rank='Diamond' img='card-4.jpeg' details={'Offre diamond ultime'} support={''}/>
        </div>

        <div className='mt-16'>
          <h1 className='uppercase text-pink-red font-Inter tracking-[0.2rem]'>FAQ</h1>
          <p className='text-white font-Poppins text-[1.5rem] w-[19.75rem] mx-auto mb-12 md:w-[47rem] md:text-[3.5rem] md:mb-24'><span className='text-pink-red'>B Partner Club</span><br/>en quelques questions</p>
          <QuestionClub q='L’affiliation au B Partner Club est-elle payante ?' rep='L’affiliation au programme de fidélité B Partner Club est gratuite pour tous les clients B Partner'/>
          <QuestionClub q='Comment puis-je gagner des points B Partner Club ?' rep='B Partner Club récompense les clients pour l’usage de leur compte B Partner. Pour cumuler des points il suffit donc d’ouvrir un compte, d’utiliser ses moyens de paiements, ou encore de parrainer vos proches et amis'/>
          <QuestionClub q='Quels sont les types de points B Partner Club ?' rep='Il y en a de deux sortes
Les points de Fidélités qui sont accumulés grâce à l’ouverture et à l’utilisation de votre compte et de vos moyens de paiement
Les points de Statut qui eux sont liés à l’obtention d’un statut supplémentaire'/>
          <QuestionClub q='Quels sont les différents statuts B Partner Club ?' rep='Le B Partner Club est structuré autour de 4 niveaux : Silver, Gold, Platinium et Diamond. Chaque statut octroie des avantages distincts.
 
Pour atteindre un niveau supérieur le client B Partner devra accumuler suffisamment de points sur une année entière. Le statuts est octroyé ensuite pour une année complète.'/>
          <QuestionClub q='Quelle est la différence entre les points de Fidélité et les points de Statut ?' rep='Les points de Fidélité ne périment pas et sont acquis à vie par le client B Partner, ils pourront être échangés contre du cash-back, contre des coupons chez nos partenaires, ou encore utilisés pour paiements certains frais et services complémentaires.
 
Les points de Statut sont valables pour une année calendaire, et sont uniquement utilisés pour le passage à un niveau de Statut supérieur. La démarche est automatique et nécessite aucune action de la part du Client'/>
          <QuestionClub q='Où puis-je accéder à mes points B Partner Club ?' rep='Le point de Fidélité ainsi que le Statut du client au B Partner Club sont visible sur l’Application, sur la page d’accueil de votre compte.'/>
        </div>

        <Footer />
      </div>
    </div>
  );
}
