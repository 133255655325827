import './App.css';
import Landing from './pages/Landing';
import LandingPremium from './pages/LandingPremium';
import{Routes,Route, HashRouter} from 'react-router-dom'
import LandingPrestige from './pages/LandingPrestige';
import ScrollToTop from './hooks/ScrollToTop';
import ContactForm from './pages/ContactForm';
import Comparatif from './pages/Comparatif';
import HomeClub from './pages/HomeClub.js';
function App() {
  return (
    <HashRouter>
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/premium' element ={<LandingPremium />}/>
        <Route path='/prestige' element ={<LandingPrestige/>} />
        <Route path='/contact' element={<ContactForm/>}/>
        <Route path='/Comparaison-offres' element={<Comparatif />}/>
        <Route path='/Club' element={<HomeClub />} />
      </Routes>
    </div>
    </HashRouter>
  );
}

export default App;
