import { useState, useEffect, useRef } from 'react';

// Hook personnalisé pour suivre la position de la div
function useScrollDistanceFromDiv() {
  const [distance, setDistance] = useState(0);
  const divRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (divRef.current) {
        const divTop = divRef.current.getBoundingClientRect().top;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const offset = divTop + scrollTop;
        const distanceFromDiv = window.scrollY - offset;

        setDistance(distanceFromDiv);
      }
    };

    // Appeler handleScroll une première fois pour la mise à jour initiale
    handleScroll();

    // Ajout de l'événement de scroll
    window.addEventListener('scroll', handleScroll);

    // Nettoyage de l'événement de scroll
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { distance, divRef };
}

export default useScrollDistanceFromDiv;
