import React, { useState } from "react";
import { Link } from "react-router-dom";
import { APP_URL } from "../globalVariables";
import CommingSoon from "./CommingSoon";
const TopBar = () => {
  const [burger, setToggle] = useState(false);
  function toggle() {
    setToggle(!burger);
  }

  const [popup, setPopup] = useState(false);
  console.log(popup)
  const togglep = ()=>{
      setPopup(!popup);
  }
  return (
    <div>
      <CommingSoon popup={popup} toggle={togglep}/>
    <div
      className={`h-20 w-full grid grid-cols-3 ${
        !burger ? "bg-gray-700 bg-opacity-20" : "bg-black"
      } backdrop-filter backdrop-blur-lg justify-between md:justify-start items-center fixed top-0 z-50 md:h-[6.5rem]`}
    >
      {!burger ? (
        <img
          className="w-7 h-7 ml-11 md:hidden"
          src="TopBarBurger.svg"
          alt={"burger menu"}
          onClick={toggle}
        />
      ) : (
        <img
          className="w-5 h-5 ml-11 md:hidden"
          src="close.svg"
          alt={"close menu bouton"}
          onClick={toggle}
        />
      )}

      <div className="w-full flex justify-center md:col-start-1 md:w-fit">
        <Link to="/">
          <img
            className="w-14 h-7 md:w-[4.75rem] md:h-[5rem] md:ml-[1.5rem] lg:ml-[5rem] lg:w-[5.3rem]"
            src="logo.svg"
            alt={"BPartner logo"}
          />
        </Link>
      </div>
      <div className="hidden md:flex md:flex-row md:visible -ml-14 lg:justify-end">
      <Link
          to="/comparaison-offres"
          className="w-[4.75rem] h-[2.5rem] flex justify-center items-center leading-none text-white font-DMSans text-[0.75rem] font-medium md:mr-[1rem] lg:text-base lg:mr-[2.5rem]"
        >
          Nos offres
        </Link>
        <Link
          to="/prestige"
          className="w-[4.75rem] h-[2.5rem] flex justify-center items-center leading-none text-white font-DMSans text-[0.75rem] font-medium md:mr-[1rem] lg:text-base lg:mr-[2.5rem]"
        >
          Prestige
        </Link>
        <Link
          to="/premium"
          className="w-[4.75rem] h-[2.5rem] flex justify-center items-center leading-none text-white font-DMSans text-[0.75rem] font-medium md:mr-[1rem] lg:text-base lg:mr-[2.5rem]"
        >
          Premium
        </Link>
        <Link
          to="/Club"
          className=" hidden w-[4.75rem] h-[2.5rem] flex justify-center items-center leading-none text-white font-DMSans text-[0.75rem] font-medium md:mr-[1rem] lg:text-base lg:mr-[2.5rem]"
        >
          Club
        </Link>
      </div>
      <div className="md:flex items-center md:justify-end">
        <a
          className="w-[4.75rem] cursor-pointer h-[2.5rem] hidden md:visible md:flex justify-center items-center leading-none text-white font-DMSans text-[0.75rem] font-medium  mr-[1rem] lg:mr-[1.5rem] lg:text-base lg:w-fit"
          onClick={togglep}
        >
          Se connecter
        </a>
        <a
          className="px-1 h-7 cursor-pointer w-18 mr-5 md:h-[2.5rem] bg-white py-2 rounded-sm font-DMSans text-[0.5rem] leading-none md:col-start-3 lg:mr-[5rem] lg:w-[12.0625rem] lg:text-base"
          onClick={togglep}
        >
          Ouvrir un compte
        </a>
      </div>
      {burger ? (
        <div className="w-full h-[100vh] z-1000 bg-black absolute top-20 flex flex-col items-center">
          <Link
            to="/comparaison-offres"
            className="text-white w-full h-[2.5rem] text-[1.125rem] font-DMSans font-medium mt-[2rem]"
          >
            Nos offres
          </Link>
          <Link
            to="/prestige"
            className="text-white w-[5.3125rem] h-[2.5rem] text-[1.125rem] font-DMSans font-medium mt-[2rem]"
          >
            Prestige
          </Link>
          <Link
            to="/premium"
            className="text-white w-[5.3125rem] h-[2.5rem] text-[1.125rem] font-DMSans font-medium mt-[2rem]"
          >
            Premium
          </Link>
          <Link
            to="/Club"
            className="text-white w-[5.3125rem] h-[2.5rem] text-[1.125rem] font-DMSans font-medium mt-[2rem] hidden"
          >
            Club
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>

  </div>
  );
};

export default TopBar;
