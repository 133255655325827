import React,{useState} from 'react'

export default function QuestionClub(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = ()=>{
        setOpen(!open);
    }
  return (
    <div className={`flex flex-col w-[19.5rem] md:w-[50rem] mx-auto text-white border-b border-[#cc133E] mt-4 pb-4 ${props.rep === "" ? 'hidden' : ""}`}>
        <div className='flex flex-row items-center'>
            <p className='text-left font-Poppins font-medium text-[1.375rem] mr-2'>{props.q}</p>
            <img className={`${!open ? "w-[1.25rem] h-[1.25rem]" : "w-[1rem] h-[1rem]"} cursor-pointer ml-auto mb-auto mt-[8px]`} onClick={handleOpen} alt='voir plus' src={`${open ? 'croix-red.png' : 'white-plus.png'}`}></img>
        </div>
        <div className={`${open ? '' : 'hidden'} text-left font-Poppins font-normal text-[0.75rem] mt-3`}>
            {props.rep}
        </div>
    </div>
  )
}