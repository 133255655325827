import React from 'react';

export default function Card(props) {
  return (
    <div className='relative w-[19.875rem] h-[23.50688rem] mx-auto overflow-hidden my-4 md:w-[17.5rem] md:h-[21.5rem]'>
      <img src={props.img} className='w-full h-full img-black-and-white object-cover' alt="Card Image" />
      <div className='absolute inset-0 bg-black bg-opacity-50 flex'>
        <div className='text-white mt-36 w-full ml-8 text-left '>
            <p className='font-Poppins text-[1.6rem] font-semibold '>{props.rank}</p>
            <p className=' font-Poppins text-pink-red font-semibold'>B Partner Club</p>
            <p className='font-Poppins text-[0.8rem] font-semibold mt-2'>{props.details}</p>
            <p className='font-Poppins text-[0.8rem] font-semibold mt-1 border-b-2 border-pink-red py-1 mr-8'>{props.support}</p>
            <div className={`hidden flex flex-row items-center mt-8 cursor-pointer ${props.rank === 'Diamond' ? 'hidden' : ''}`}><img src='arrow-right.png' className='h-[1.125rem] w-auto'/><p className='uppercase font-Poppins font-semibold ml-1'>learn more</p></div>
        </div>
      </div>
    </div>
  );
}